
import {Line} from "vue-chartjs";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  extends: Line,
})
export default class TimeSeriesChart extends Vue<Line> {
  @Prop()
  private chartData: any;
  @Prop()
  private chartOptions: any;

  constructor() {
    super();
  }

  public mounted() {
    this.renderChart(this.chartData, this.chartOptions);
    this.$emit("render", this.$refs.canvas);
  }
}
