export const chartJSDataToCSV = (data: any) => {
  let csvContent = "data:text/csv;charset=utf-8,";

  csvContent += "Date," + data.datasets.map((dataset: any) => dataset.label).join(",") + "\n";
  const dataArrays = data.datasets.map((dataset: any) => dataset.data);
  csvContent += dataArrays[0].map((_: any, i: number) =>
    [dataArrays[0][i].x, ...dataArrays.map((array: any[]) => array[i].y)].join(","),
  ).join("\n");

  return csvContent;
};
